<footer id="footer" class="parallax section section-parallax h-100 mt-5" data-plugin-parallax data-plugin-options="{'speed': 0.1, 'horizontalPosition': '100%'}" data-image-src="img/parallax/parallax-3.jpg">
    <div class="container pt-4">
        <div class="row py-2 my-4">
            <div class="col-md-6 mb-4 mb-lg-0">
                <div class="row">
                    <a href="index.html" class="logo pr-0 pr-lg-3">
                        <img alt="Turkus Turizm" src="assets/img/turkussliderlogo.png" class="bottom-4" height="70">
                    </a>
                    <div class="py-2 my-2">
                        <h2 class="word-rotator letters type">
                            <span class="word-rotator-words waiting">
                                <b class="is-visible">Seyehat Acentası !</b>
                                <b>Taşımacılık !</b>
                                <b>Yatırım Danışmanlığı !</b>
                            </span>
                        </h2>
                    </div>
                </div>

                <p class="mt-2 mb-2">
                    Her gün bir yerden göçmek ne iyi.<br /> 
                    Her gün bir yere konmak ne güzel.<br /> 
                    Bulanmadan, donmadan akmak ne hoş.<br />
                    Dünle beraber gitti, cancağızım.<br /> 
                    Ne kadar söz varsa düne ait. <br />
                    Şimdi yeni şeyler söylemek lâzım.<br /> Mevlâna
                </p>
                <p class="mb-0"><a href="plusdisc_profesyoneller_icin_kisilik_degerlendirme_sistemleri.html" class="btn-flat btn-xs text-color-light"><strong class="text-2">devamı...</strong><i class="fas fa-angle-right p-relative top-1 pl-2"></i></a></p>
            </div>
            <div class="col-md-6">
                <h5 class="text-3 mb-3">İLETİŞİM</h5>
                <div class="row">
                    <div class="col-md-6">
                        <ul class="list list-icons list-icons-lg">
                            <li class="mb-1"><i class="far fa-dot-circle text-color-primary"></i><p class="m-0">18. Sk No:1, Obagol 07400 Alanya/Antalya</li>
                            <li class="mb-1"><i class="fas fa-phone-volume text-color-primary"></i><p class="m-0"><a href="tel:+902425141479">(0242) 514 14 79</a></p></li>
                            <li class="mb-1"><i class="fas fa-mobile-alt text-color-primary"></i><p class="m-0"><a href="tel:+905317079444">(0531) 707 94 44</a></p></li>
                            <li class="mb-1"><i class="far fa-envelope text-color-primary"></i><p class="m-0"><a href="mailto:info@turkusturizm.com.tr">info@turkusturizm.com.tr</a></p></li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list list-icons list-icons-sm">
                            <li><i class="fas fa-angle-right"></i><a href="#" class="link-hover-style-1 ml-1"> Hakkımızda</a></li>
                            <li><i class="fas fa-angle-right"></i><a href="#" class="link-hover-style-1 ml-1"> Sıkça Sorulan Sorular</a></li>
                            <li><i class="fas fa-angle-right"></i><a href="#" class="link-hover-style-1 ml-1"> Bize Ulaşın</a></li>
                            <li><i class="fas fa-angle-right"></i><a href="#" class="link-hover-style-1 ml-1"> Kişisel Verilerin Korunma Kanunu</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-copyright">
        <div class="container py-2">
            <div class="row py-4">
                <div class="col-lg-7 text-center">
                    <p class="text-2 mt-1 text-color-light">© Copyright {{today | date:'yyyy'}}&nbsp;&nbsp;&nbsp; Tüm Hakları Saklıdır. &nbsp;&nbsp;| &nbsp;&nbsp;www.turkusturizm.com.tr</p>
                </div>
                <div class="col-lg-5 text-right text-md-left">
                    <img src="assets/img/payment-icon.png" alt="Payment icons" class="img-fluid mt-4 mt-lg-2">
                </div>
            </div>
        </div>
    </div>
</footer>
