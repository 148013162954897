
<app-top-menu></app-top-menu>
<div role="main" class="main">
    <div class="slider-container rev_slider_wrapper" style="height: 500px;">
        <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider data-plugin-options="{'delay': 9000, 'gridwidth': 1170, 'gridheight': 500, 'responsiveLevels': [4096,1200,992,500], 'navigation' : {'arrows': { 'enable': false }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">
            <ul>
                <li data-transition="fade">
                    <img src="assets/img/slides/slide_plusdisc_1.jpg"
                         alt=""
                         data-bgposition="right center"
                         data-bgpositionend="center center"
                         data-bgfit="cover"
                         data-bgrepeat="no-repeat"
                         data-kenburns="on"
                         data-duration="9000"
                         data-ease="Linear.easeNone"
                         data-scalestart="110"
                         data-scaleend="100"
                         data-rotatestart="0"
                         data-rotateend="0"
                         data-offsetstart="0 0"
                         data-offsetend="0 0"
                         data-bgparallax="0"
                         class="rev-slidebg">

                    <div class="tp-caption"
                         data-frames='[{"delay":3000,"speed":2000,"frame":"0","from":"x:-30%;y:20%;opacity:0;","to":"x:0;y:0;o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                         data-x="center" data-hoffset="['350','350','350','200']"
                         data-y="center" data-voffset="['-50','-50','-50','-100']"><img src="assets/img/turkussliderlogo.png" alt=""></div>

                    <h1 class="tp-caption text-color-dark font-weight-bold negative-ls-2 opacity-8"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:1.5;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-x="center" data-hoffset="['-230','-230','-230','-340']"
                        data-y="center" data-voffset="['-200','-200','-200','-200']"
                        data-fontsize="['30','30','30','35']"
                        data-lineheight="['55','55','55','35']">
                        Turizm ve Seyehat Acentası
                    </h1>

                    <div class="tp-caption"
                         data-x="center" data-hoffset="['-340','-340','-340','-460']"
                         data-y="center" data-voffset="['-150','-150','-150','-150']"
                         data-start="1000"
                         data-transform_in="x:[300%];opacity:0;s:500;"><img src="assets/img/favicon.png"alt=""></div>
                    <div class="tp-caption"
                         data-x="center" data-hoffset="['-340','-340','-340','-460']"
                         data-y="center" data-voffset="['-100','-100','-100','-100']"
                         data-start="1000"
                         data-transform_in="x:[300%];opacity:0;s:500;"><img src="assets/img/favicon.png" alt=""></div>
                    <div class="tp-caption"
                         data-x="center" data-hoffset="['-340','-340','-340','-460']"
                         data-y="center" data-voffset="['-50','-50','-50','-50']"
                         data-start="1000"
                         data-transform_in="x:[300%];opacity:0;s:500;"><img src="assets/img/favicon.png" alt=""></div>

                    <div class="tp-caption font-weight-bold text-color-dark opacity-8"
                         data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                         data-x="center" data-hoffset="['-250','-250','-250','-340']"
                         data-y="center" data-voffset="['-150','-150','-150','-150']"
                         data-fontsize="['20','20','20','25']"
                         data-lineheight="['30','30','30','10']"
                         style="color: #b5b5b5;"><a href="#" class="text-color-quaternary">Yurt İçi Turlar</a></div>

                    <div class="tp-caption font-weight-bold text-color-dark opacity-8"
                         data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                         data-x="center" data-hoffset="['-260','-260','-260','-350']"
                         data-y="center" data-voffset="['-100','-100','-100','-100']"
                         data-fontsize="['20','20','20','25']"
                         data-lineheight="['30','30','30','10']"
                         style="color: #b5b5b5;">Taşımacılık</div>

                    <div class="tp-caption font-weight-bold text-color-dark opacity-8"
                         data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                         data-x="center" data-hoffset="['-210','-210','-210','-320']"
                         data-y="center" data-voffset="['-50','-50','-50','-50']"
                         data-fontsize="['20','20','20','25']"
                         data-lineheight="['30','30','30','10']"
                         style="color: #b5b5b5;">Yatırım Danışmanlığı</div>
                </li>
            </ul>
        </div>
    </div>

    <div class="container">
        <div class="row mt-5 mb-0">
            <div class="col-lg-4 appear-animation" data-appear-animation="fadeInRightShorter">
                <h4 class="font-weight-normal text-6 line-heigh-2 mb-4"><strong class="font-weight-bold">Turkus</strong> Turizm</h4>
                <p>Gezilerinizden iz bırakmayan, öğretici olmayan, hızlıca tüketilmiş bir tur tecrübesiyle değil, kalıcı bir kültürel hafızayla geri dönerken dünyanın çeşitliliğini tarihsel ve kültürel bir filtreden geçirecek yeni yaklaşımlara sahip olur ve kendinizi bir şeylerin “eksik” kaldığı duygusuyla tekrar aynı yere gitmek zorunda hissetmezsiniz. Böylece yeni yerler keşfetme duygunuz TURKUS TURİZM ile her zaman canlı kalır.</p>
                <a href="#" class="learn-more text-color-primary font-weight-semibold text-2">devamı...<i class="pl-2 fas fa-chevron-right text-2"></i></a>
            </div>
            <div class="col-lg-8">
                <div class="row">
                    <div class="col-lg-6 mb-lg-3 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
                        <h4 class="font-weight-normal text-6 line-heigh-2"><strong class="font-weight-bold">Turkus</strong> ile</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mb-4 mb-lg-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="200">
                        <div class="feature-box feature-box-style-5">
                            <div class="feature-box-icon">
                                <i class="fa fa-bus icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-2">9 kişiden 46 kişiye kadar koltuk kapasite çeşitliliğine sahip yeni model araç filomuz ile tüm taşımacılık faaliyetlerimizi kendi kaynaklarımız ile gerçekleştiririz.</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4 mb-lg-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="400">
                        <div class="feature-box feature-box-style-5">
                            <div class="feature-box-icon">
                                <i class="fas fa-users icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-2">Herbiri kendi alanında deneyimli çalışanlarımız ile 7 gün 24 saat boyunca bütün iş süreçlerimizi kusursuza en yakın şekilde gerçekleştiririz.</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 mb-4 mb-lg-5">
                        <div class="feature-box feature-box-style-5 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
                            <div class="feature-box-icon">
                                <i class="fas fa-life-ring icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-1">Hizmet sunduğumuz paydaşlarımız için faaliyet alanlarımız ile ilgili yasaların ve talimatların gerekliliği bütün şartları yerine getiririz.</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-4 mb-lg-5">
                        <div class="feature-box feature-box-style-5 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="600">
                            <div class="feature-box-icon">
                                <i class="fas fa-desktop icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-1">Gerek iş ortaklarımızın gerek müşterilerimizin kişisel bilgilerinin gizliliğine verdiğimiz önem ve operasyonlarımızın verimliliğini en yüksek düzeyde tutmak için tamamen kendimize ait araçlar ile teknolojinin bütün imkanlarını kullanırız.</h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6 mb-0 mb-lg-0">
                        <div class="feature-box feature-box-style-5 appear-animation" data-appear-animation="fadeInRightShorter" data-appear-animation-delay="800">
                            <div class="feature-box-icon">
                                <i class="fa fa-info-circle icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-2">Müşteri memnuniyetini en üst düzeyde tutmak için bütün iletişim kanallarımızı açık tutarak müşteri ilişkilerine önem veririz.</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-0 mb-lg-5">
                        <div class="feature-box feature-box-style-5 appear-animation" data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="800">
                            <div class="feature-box-icon">
                                <i class="fas fa-chart-bar icons"></i>
                            </div>
                            <div class="feature-box-info">
                                <h6 class="font-weight-semibold mb-2">Her yıl yaklaşık yerli ve yabancı 80.000 kişiye faaliyetlerimiz kapsamında hizmet verirken hedeflerimizi her yıl güncelleriz.</h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="container py-2">
        <div class="row justify-content-center">
            <div class="col-sm-10 col-md-7 col-lg-4 mb-3 mb-lg-0">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-primary my-4">
                    <div class="featured-box">
                        <div class="box-content px-lg-2 px-xl-4 py-lg-5">
                            <h2 class="font-weight-normal text-5"><strong class="font-weight-extra-bold">Yetkili Turizm</strong> Acentası</h2>
                            <p class="mb-0">TURSAB tan yetkili turizm acentamız ile Akdeniz,Ege ve Marmara bölgelerinde mutlu müşteri odaklı olarak tüm gücümüzle çalışıyoruz</p>
                            <a href="#" class="btn btn-light btn-outline font-weight-semibold text-color-dark btn-px-5 btn-py-2 border-width-1 text-1 mt-3">İNCELE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-10 col-md-7 col-lg-4 mb-3 mb-lg-0">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-primary my-4">
                    <div class="featured-box">
                        <div class="box-content px-lg-3 px-xl-5 py-lg-5">
                            <h2 class="font-weight-normal text-5"><strong class="font-weight-extra-bold">Taşımacılık</strong> Hizmetleri</h2>
                            <p class="mb-0">Ulaştırma Bakanlığı'nın bütün yetki belgelerine sahip firmamızla, Türkiye nin bütün bölgelerinde taşımacılık faaliyetleri gerçekleştiriyoruz</p>
                            <a href="#" class="btn btn-light btn-outline font-weight-semibold text-color-dark btn-px-5 btn-py-2 border-width-1 text-1 mt-3">İNCELE</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-10 col-md-7 col-lg-4 mb-3 mb-lg-0">
                <div class="featured-boxes featured-boxes-modern-style-2 featured-boxes-modern-style-primary my-4">
                    <div class="featured-box">
                        <div class="box-content px-lg-3 px-xl-5 py-lg-5">
                            <h2 class="font-weight-normal text-5"><strong class="font-weight-extra-bold">Yatırım</strong> Danışmanlığı</h2>
                            <p class="mb-0">Emlak alım satım ve kiralama süreçlerinde alıcı, satıcı arasındaki ilişkinin temelinde güven duymak yatar. Bu güveni sağlıyoruz.</p>
                            <a href="#" class="btn btn-light btn-outline font-weight-semibold text-color-dark btn-px-5 btn-py-2 border-width-1 text-1 mt-3">İNCELE</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<hr>

<section id="who-we-are" class="section section-no-border bg-color-light pb-5 m-0">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <p>
                    <strong class="font-weight-semibold text-color-dark">
                        Seyahat, yolculuk, gezi antik çağlardan modern zamanlara insanoğlunun sürekli ilgilendiği en ilginç konulardan biri olmuştur. İnsanlık tarihinin en ilgi çekici serüvenleri eski yolculuklarla ilgilidir. İster Gılgamış ya da Herakles olsun, isterse Odysseus ya da Argonotlar, hepsinin de yolculukları bizleri gezme sevdasının peşine düşmeye zorlamıştır.

                        Sömürgeci kâşif, savaşçı fatih, yeni pazarlar arayan gezgin tüccar… Farklı hedefler ve mekânlar bile keşif merakımızı durduramamıştır. Tüccarlar Çin’den ta Endülüs’e kadar mallarını hangi güzergâhlardan taşıdılar? 100 bin kişilik bir ordu İstanbul’dan Bağdat’a ya da Viyana’ya nasıl gitti? Böylesi bir seferin lojistik sorunları neydi? Ya da gezgin bilgelerin, dervişlerin, abdalların iç yolculukları neleri içeriyordu?
                    </strong>
                </p>
                <p>
                   İşte Turkus Turizm Seyehat Acentası ticari faaliyetten öte, sektöre bakışındaki farklı yaklaşımdan doğan ruh ile müşterilerine en iyi hizmeti sunmayı amaçlar. 
                </p>
            </div>
            <div class="col-lg-6 custom-height text-center">
                <img src="assets/img/home/plusdisc_who-we-are-1.jpg" alt class="custom-image-style-1 _left" data-appear-animation="zoomIn" data-appear-animation-delay="1000" />
                <img src="assets/img/home/plusdisc_who-we-are-3.jpg" alt class="custom-image-style-1 _left_2" data-appear-animation="zoomIn" data-appear-animation-delay="2000" />
                <img src="assets/img/home/plusdisc_who-we-are-2.jpg" alt class="custom-image-style-1 _right" data-appear-animation="zoomIn" data-appear-animation-delay="1500" />
            </div>
        </div>
    </div>
</section>

<section class="section parallax section-parallax section-no-border custom-z-index m-0" data-plugin-parallax data-plugin-options="{'speed': 1.5}" data-image-src="img/parallax/parallax-1.jpg">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-7">
            </div>
            <div class="col-lg-9">
                <div class="appear-animation" data-appear-animation="flipInX" data-appear-animation-delay="1200">
                    <h4 class="text-color-light">
                        Bireysel ve kurumsal anlamda sürekli gelişmeyi kendine felsefe edinen Turkus Turizm, misafirlerine en iyi olanı sunmak isteyen çalışanları ile güçlü aile bütünlüğünü sürdürmeyi, toplumun her kesiminden misafirlerine yüksek kalitede hizmet sunmayı, turizm sektöründe iş imkanı yaratarak ülkemize hizmet etmeyi, personellerinin sürekli gelişmesini ve güven içinde mutlu çalışmasını sağlamayı hedeflemiştir.
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container p-5">
    <div class="row text-center">
        <div class="col-lg-12 mb-lg-3 appear-animation" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="200">
            <h4 class="font-weight-normal text-6"><strong class="font-weight-bold">Turkus Turizm</strong>'in artıları;</h4>
        </div>

        <div class="featured-boxes featured-boxes-style-3 featured-boxes-flat">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <div class="featured-box featured-box-secondary featured-box-effect-3">
                        <div class="box-content box-content-border-top">
                            <i class="icon-featured fas fa-chart-line"></i>
                            <h4 class="font-weight-semibold text-4 mt-3">Haberinizin olmadığı ekstra ücretler ile karşılaşmassınız.</h4>
                            <p class="mb-2 mt-2 text-2">Bütün faaliyet alanlarımızda, ücretler profosyonel ekibimiz ile hazırlanarak net bir maliyet hesabı sunulur. Bu sebepten dolayı sürpriz ek maliyetlere maruz kalmassınız.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="featured-box featured-box-secondary featured-box-effect-3">
                        <div class="box-content box-content-border-top">
                            <i class="icon-featured fas fa-bus"></i>
                            <h4 class="font-weight-semibold text-5 mt-3">Araçlar</h4>
                            <p class="mb-2 mt-2 text-2">Hizmet verirken kullandığımız bütün araçlarımız, yasal mevzuata uygundur. Taşımacılık faaliyetleriniz kendi öz mallarımız ile sağlamaktayız. Bu nedenle araçlar ile ilgili tüm sürece hakimiz.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="featured-box featured-box-secondary featured-box-effect-3">
                        <div class="box-content box-content-border-top">
                            <i class="icon-featured fas fa-user"></i>
                            <h4 class="font-weight-semibold text-4 mt-3">Çalışanlarımız</h4>
                            <p class="mb-2 mt-2 text-2">Yaklaşık 30 kişiden oluşan ve her geçen gün sayısı artan ekibimizin, firmamızın başarısında kilit nokta olduğunun farkındayız. Bu yüzden en iyi hizmeti verebilmek için sürekli eğitim, inovasyon ve kalite standartları ile alakalı çalışmalar yapıyoruz. </p>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</div>

<section class="section section-primary border-top-0 mb-0 m-0 p-3">

    <div class="row text-center p-4 d-md-flex">
        <div class="col-lg-12 mb-lg-0">
            <h2 class="word-rotator letters rotate-3 mb-0">
                <span><strong class="font-weight-bold">Turkus Turizm</strong> ile, </span>
                <span class="word-rotator-words">
                    <b class="is-visible">Seyahatin&nbsp;verdiği&nbsp;özgürlüğü</b>
                    <b>Ekonomik&nbsp;te&nbsp;gezilebilindiğini</b>
                    <b>Profesyonel&nbsp;ekip&nbsp;ile&nbsp;çalışmanın&nbsp;avantajlarını</b>
                </span>
                <span> keşfedebilirsiniz.</span>
            </h2>
        </div>
    </div>
</section>
<app-footer></app-footer>
<router-outlet></router-outlet>