<header id="header" class="header-effect-shrink" data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 70}">
    <div class="header-body border-top-0">
        <div class="header-top header-top-borders">
            <div class="container h-100">
                <div class="header-row h-100">
                    <div class="header-column justify-content-start">
                        <div class="header-row">
                            <nav class="header-nav-top">
                                <ul class="nav nav-pills">
                                    <li class="nav-item nav-item-borders py-2">
                                        <a href="tel:024245141479"><i class="fas fa-phone-volume text-4 text-color-primary" style="top: 0;"></i> (0242) 514 14 79</a>
                                    </li>
                                    <li class="nav-item nav-item-borders py-2 d-lg-inline-flex">
                                        <a href="mailto:info@turkusturizm.com.tr"><i class="far fa-envelope text-4 text-color-primary" style="top: 1px;"></i> info@turkusturizm.com.tr</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="header-column justify-content-end">
                        <div class="header-row">
                            <nav class="header-nav-top">
                                <div>
                                    <ul class="nav nav-pills">
                                        <li class="nav-item nav-item-borders py-2 pr-0 dropdown">
                                            <a class="nav-link" href="#" role="button" id="dropdownLanguage" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <img src="assets/img/blank.gif" class="flag flag-tr" alt="Turkce" /> Türkçe
                                                <i class="fas fa-angle-down"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguage">
                                                <a class="dropdown-item" href="#"><img src="assets/img/blank.gif" class="flag flag-tr" alt="Turkce" /> Türkçe</a>
                                                <a class="dropdown-item" href="#"><img src="assets/img/blank.gif" class="flag flag-gb" alt="English" /> English</a>
                                                <a class="dropdown-item" href="#"><img src="assets/img/blank.gif" class="flag flag-pl" alt="Polski" /> Polski</a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <ul class="header-social-icons social-icons d-none d-md-block social-icons-clean">
                                        <li class="social-icons-facebook"><a href="https://www.facebook.com/groups/turcjawycieczkiatrakcje/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a></li>
                                        <li class="social-icons-instagram"><a href="https://www.instagram.com/metintour/" target="_blank" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                                        <li class="social-icons-youtube"><a href="https://www.youtube.com/channel/UCwrz9VDrYDRpKOCbp7Ly46A" target="_blank" title="Youtube"><i class="fab fa-youtube"></i></a></li>

                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-container container">
            <div class="header-row">
                <div class="header-column">
                    <div class="header-row">
                        <div class="header-logo">
                            <a href="/">
                                <img alt="Turkus Turizm" width="270" height="111" data-sticky-width="200" data-sticky-height="82" src="assets/img/logo.jpg">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="header-column justify-content-end">
                    <div class="header-row">
                        <div class="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
                            <div class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                <nav class="collapse">
                                    <ul class="nav nav-pills" id="mainNav">
                                        <li>
                                            <a class="dropdown-item active" href="/">
                                                Anasayfa
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="/">
                                                Hakkımızda
                                            </a>
                                        </li>
                                        <li class="dropdown">
                                            <a class="dropdown-item dropdown-toggle" href="#">
                                                Hizmetlerimiz
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li><a class="dropdown-item" href="#">Tur Acentası</a></li>
                                                <li><a class="dropdown-item" href="#">Taşımacılık Hizmetleri</a></li>
                                                <li><a class="dropdown-item" href="#">Yatırım Danışmanlığı</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#">
                                                S.S.S.
                                            </a>
                                        </li>
                                        <li>
                                            <a class="dropdown-item" href="#">
                                                Bize Ulaşın
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                                <i class="fas fa-bars"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
